<template>
  <div class="myorder">
    <div class="myorder_header">
      <navbarTools :leftArrow="true"></navbarTools>
      <van-tabs
        v-model:active="active"
        color="#FC7301"
        title-inactive-color="#333"
        title-active-color="#FC7301"
        @change="typeChangeFn"
      >
        <van-tab title="京东好物订单" name="0"></van-tab>
        <van-tab title="精选兑换订单" name="1"></van-tab>
      </van-tabs>
      <van-tabs
        v-model="jdActive"
        color="#FC7301"
        title-inactive-color="#333"
        title-active-color="#FC7301"
        v-if="active == '0'"
        @click-tab="jdActiveChangeFn"
      >
        <van-tab title="全部" name=""></van-tab>
        <van-tab title="待付款" name="1"></van-tab>
        <van-tab title="处理中" name="2"></van-tab>
        <van-tab title="已取消" name="3"></van-tab>
      </van-tabs>
    </div>
    <div class="benefits_content" v-if="active == '1'">
      <van-list
        v-model:loading="loading"
        :immediate-check="false"
        :finished="finished"
        @load="onloadFn"
        finished-text="没有更多了"
      >
        <van-cell>
          <div
            class="order_item"
            v-for="(item, index) in orderList"
            :key="index"
          >
            <div class="item_top">
              <div class="top_name">
                <div class="on-line">
                  {{ item?.orderGoodsList[0]?.goodsName }}
                </div>
              </div>
              <div class="top_status">{{ item?.orderStatusStr }}</div>
            </div>
            <div class="item_info">
              <div class="info_content">
                <div class="info_content_label">工钱币：</div>
                <div class="info_content_detail">
                  {{ item?.orderGoodsList[0]?.coinPrice }}币
                </div>
              </div>
              <div class="info_content">
                <div class="info_content_label">兑换时间：</div>
                <div class="info_content_detail">{{ item?.createTime }}</div>
              </div>
              <div class="info_content">
                <div class="info_content_label">订单编号：</div>
                <div class="info_content_detail">
                  <div class="info_content_detail">{{ item?.orderSn }}</div>
                  <div
                    class="info_content_detail_copy"
                    @click="copyFn(item.orderSn)"
                  >
                    复制
                  </div>
                </div>
              </div>
              <div
                class="info_content"
                v-if="
                  item?.orderDeliveryList[0]?.orderDeliveryGoodsList[0]
                    ?.goodsType == '2'
                "
              >
                <div class="info_content_label">充值方式：</div>
                <div class="info_content_detail">
                  {{
                    item?.orderDeliveryList[0]?.orderDeliveryGoodsList[0]
                      ?.virtualCardSn
                      ? "卡号密码兑换"
                      : "直接充值"
                  }}
                </div>
              </div>
              <div class="info_content" v-if="item?.remark">
                <div class="info_content_label">充值账号：</div>
                <div class="info_content_detail">
                  <div class="info_content_detail">{{ item?.remark }}</div>
                  <div
                    class="info_content_detail_copy"
                    @click="copyFn(item.remark)"
                  >
                    复制
                  </div>
                </div>
              </div>
              <div
                class="info_content info_content_copy"
                v-if="
                  item?.orderDeliveryList.length > 0 &&
                  item?.orderDeliveryList[0]?.orderDeliveryGoodsList[0]
                    ?.virtualCardSn
                "
              >
                <div class="info_content_label">卡号：</div>
                <div class="info_content_detail">
                  <div
                    class="info_content_detail_logistics"
                    v-if="item?.orderDeliveryList.length > 0"
                  >
                    {{
                      item?.orderDeliveryList[0]?.orderDeliveryGoodsList[0]
                        ?.virtualCardSn
                    }}
                  </div>
                  <div
                    class="info_content_detail_copy"
                    v-if="item?.orderDeliveryList.length > 0"
                    @click="
                      copyFn(
                        item?.orderDeliveryList[0]?.orderDeliveryGoodsList[0]
                          ?.virtualCardSn
                      )
                    "
                  >
                    复制
                  </div>
                </div>
              </div>

              <div
                class="info_content info_content_copy"
                v-if="
                  item?.orderDeliveryList.length > 0 &&
                  item?.orderDeliveryList[0]?.orderDeliveryGoodsList[0]
                    ?.virtualCardPwd
                "
              >
                <div class="info_content_label">密码：</div>
                <div class="info_content_detail">
                  <div class="info_content_detail_logistics">
                    {{
                      item?.orderDeliveryList[0]?.orderDeliveryGoodsList[0]
                        ?.virtualCardPwd
                    }}
                  </div>
                  <div
                    class="info_content_detail_copy"
                    @click="
                      copyFn(
                        item?.orderDeliveryList[0]?.orderDeliveryGoodsList[0]
                          ?.virtualCardPwd
                      )
                    "
                  >
                    复制
                  </div>
                </div>
              </div>

              <div class="info_content">
                <div class="info_content_label">兑换信息：</div>
                <div class="info_content_detail">
                  {{ item?.orderGoodsList[0]?.goodsName }}
                </div>
              </div>

              <div
                class="info_content info_content_copy"
                v-if="
                  item?.orderDeliveryList.length > 0 &&
                  item?.orderDeliveryList[0]?.expressCode != 'none'
                "
              >
                <div class="info_content_label">物流信息：</div>
                <div class="info_content_detail">
                  <div class="info_content_detail_logistics">
                    {{ item?.orderDeliveryList[0]?.expressSn }}
                  </div>
                  <div
                    class="info_content_detail_copy"
                    @click="copyFn(item?.orderDeliveryList[0]?.expressSn)"
                  >
                    复制
                  </div>
                </div>
              </div>

              <div
                class="info_content"
                v-if="
                  item?.orderDeliveryList.length > 0 &&
                  item?.orderDeliveryList[0]?.orderDeliveryGoodsList[0]
                    ?.virtualExpires
                "
              >
                <div class="info_content_label">有效期截止：</div>
                <div class="info_content_detail">
                  {{
                    item?.orderDeliveryList[0]?.orderDeliveryGoodsList[0]
                      ?.virtualExpires
                  }}
                </div>
              </div>
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>

    <div class="jdOrder pt10" v-if="active == '0'">
      <div>
        <div v-if="orderList.length">
          <van-list
            v-model:loading="loading"
            :immediate-check="false"
            :finished="finished"
            @load="onloadFn"
            finished-text="没有更多了"
          >
            <template v-for="(item, index) in orderList" :key="index">
              <div
                class="pt20 pb20 bgc-fff pl15 pr15 mb10"
                @click="goDetailFn(item)"
              >
                <div
                  class="flex justify-space-between fs16 line-height-16 mb15"
                >
                  <div class="c-333 font-weight">京东</div>
                  <div class="c-FE6507 fs14">{{ item.orderStatusStr }}</div>
                </div>
                <goods-item
                  size="small"
                  :goodsDetail="item.orderGoodsList[0]"
                  :goodsNum="item.orderGoodsList[0]?.goodsNum + ''"
                  type="order"
                ></goods-item>
                <div class="mt20 pt20 jd-border-top">
                  <div class="flex text-right c-999 fs12 line-height-12">
                    <div class="mr15">
                      共{{ item.orderGoodsList[0].goodsNum }}件商品
                    </div>
                    <div>订单金额：{{ item.orderAmount }}工钱币</div>
                  </div>
                </div>
                <div class="mt20">
                  <div
                    class="flex justify-space-between fs12 c-333"
                    v-if="item.orderStatus == 1"
                  >
                    <div>
                      请在
                      <van-count-down
                        :time="item.payRemainingSecond * 1000"
                        format="mm:ss"
                        style="display: inline-block"
                      />
                      分之前完成支付，否则订单取消哦～
                    </div>
                    <div
                      class="jd-btn-order active"
                      @click.stop="goOrderDetailFn(item.orderSn)"
                    >
                      去支付
                    </div>
                  </div>
                  <div class="flex text-right fs12 c-333" v-else>
                    <div
                      class="jd-btn-order mr10"
                      @click.stop="goLogisticsFn(item)"
                      v-if="
                        item?.orderDeliveryList.length > 0 &&
                        item?.orderDeliveryList[0]?.expressCode &&
                        item?.orderDeliveryList[0]?.expressCode != 'none'
                      "
                    >
                      查看物流
                    </div>
                    <div
                      class="jd-btn-order"
                      @click.stop="goOrderDetailFn(item.orderSn)"
                    >
                      查看详情
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </van-list>
          <!-- <div class="text-center fs14 c-999 mt20 mb5">— 到底啦 —</div> -->
        </div>

        <!-- 无订单布局 -->
        <div class="mt65 text-center fs16 c-999" v-else>
          <div class="fs0 line-height-0 mb15">
            <img class="h-60" :src="OSS_URL + '/shop/no-order.png'" />
          </div>
          <div>您还没有相关订单呢～</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navbarTools from "@/components/navbarTools";
import { defineComponent, reactive, toRefs, ref } from "vue";
import useClipboard from "vue-clipboard3";
import { Toast } from "vant";
import { orderList } from "@/api/shop";
import { useRoute, useRouter } from "vue-router";
import goodsItem from "@/components/shop/goodsItem";
export default defineComponent({
  components: {
    navbarTools,
    goodsItem,
  },
  setup() {
    const { toClipboard } = useClipboard();
    const $route = useRoute();
    const $router = useRouter();
    const state = reactive({
      active: $route.query.goodsChannel ?? "0",
      jdActive: "",
      pages: {
        page: 1,
        pageSize: 10,
        orderChannel: $route.query.goodsChannel
          ? parseInt($route.query.goodsChannel) + 1
          : 1,
        orderStatus: "",
      },
      orderList: [],
      OSS_URL: process.env.VUE_APP_OSS_URL,
      loading: false,
      finished: false,
    });
    const copyFn = (msg) => {
      try {
        toClipboard(msg);
        Toast.success("复制成功");
      } catch (e) {
        Toast.fail(e);
      }
    };
    const orderListFn = async () => {
      if (state.active == "1") {
        state.pages.orderStatus = null;
      }
      let rs = await orderList(state.pages);
      if (rs.code === 0) {
        let list = rs.data.list;
        if (state.active == "0") {
          list.map(
            (item) =>
              (item.orderGoodsList[0].goodsThumbImageList = [
                item.orderGoodsList[0].goodsThumbImage,
              ])
          );
        }
        state.orderList = [...state.orderList, ...list];
        if (state.pages.page >= rs.data.totalpage) {
          state.finished = true;
        } else {
          state.loading = false;
        }
      }
    };
    const onloadFn = async () => {
      setTimeout(() => {
        state.pages.page = state.pages.page + 1;
        orderListFn();
      }, 1500);
    };
    const jdActiveChangeFn = (e) => {
      state.pages.page = 1;
      state.pages.orderStatus = e.name;
      state.orderList = [];
      state.loading = false;
      state.finished = false;
      orderListFn();
    };
    const goDetailFn = (options) => {
      $router.push({
        path: "/jdshop/goodsDetail",
        query: { goodsId: options.orderGoodsList[0].goodsId },
      });
    };
    const finishCountDown = () => {
      state.pages.page = 1;
      state.orderList = [];
      orderListFn();
    };
    const goOrderDetailFn = (orderSn) => {
      $router.push({
        path: "/jdshop/orderdetail",
        query: {
          orderSn,
        },
      });
    };
    const goLogisticsFn = (options) => {
      console.log(options);
      $router.push({
        path: "/jdshop/orderTracking",
        query: {
          orderSn: options.orderSn,
          expressName: options.orderDeliveryList[0].expressName,
          expressSn: options.orderDeliveryList[0].expressSn,
        },
      });
    };
    const typeChangeFn = (e) => {
      state.pages.orderChannel = parseInt(e) + 1;
      state.pages.orderStatus = null;
       state.pages.page = 1;
      state.orderList = [];
       state.loading = false;
      state.finished = false;
      orderListFn();
    };
    orderListFn();
    return {
      copyFn,
      onloadFn,
      goDetailFn,
      finishCountDown,
      jdActiveChangeFn,
      goOrderDetailFn,
      typeChangeFn,
      goLogisticsFn,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.myorder {
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  ::v-deep(.van-tabs) {
    padding-top: 5px;
    .van-tab {
      font-size: 16px;
    }
    .van-tabs__line {
      width: 18px;
      height: 4px;
    }
  }
  .myorder_header {
    position: sticky;
    top: 0px;
    z-index: 999;
    background: #f2f2f2;
  }
  .benefits_content {
    margin-top: 10px;
    color: #333;
    .van-cell {
      background: transparent;
      padding: 0px 10px;
      border-radius: 8px;
    }
    .order_item {
      box-sizing: border-box;
      background: rgba(255, 255, 255, 1);
      border-radius: 5px;
      margin-bottom: 9px;
      padding: 10px;
      .item_top {
        border-bottom: 1px solid rgba(238, 238, 238, 1);
        height: 30px;
        display: flex;
        justify-content: space-between;
        .top_name {
          font-weight: 600;
          color: rgba(51, 51, 51, 1);
          width: 240px;
          font-size: 15px;
        }
        .top_status {
          font-weight: 400;
          color: #fc7301;
        }
      }
      .item_info {
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        padding: 5px 0px;
        .info_content {
          display: flex;
          line-height: 25px;
          .info_content_label_to_gifts {
            .top_right_text {
              display: inline-block;
              vertical-align: middle;
            }
            .top_right_text_top {
              padding-top: 5px;
            }
          }
          .info_content_label {
            // width: 65px;
            width: 30%;
          }
          .info_content_detail {
            width: 70%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .info_content_detail_logistics {
              display: inline-block;
            }
            .info_content_detail_copy {
              border: 1px solid rgba(160, 160, 160, 1);
              border-radius: 2px;
              color: rgba(153, 153, 153, 1);
              padding: 0px 6px;
              display: inline-block;
            }
          }
        }
        .info_content_copy {
          margin-bottom: 3px;
        }
      }
    }
    .no_list_box {
      text-align: center;
      margin-top: 110px;
      box-sizing: border-box;
      .no_list_img {
        width: 114px;
        height: 93px;
      }
      .no_list_text {
        margin-top: 10px;
      }
    }
  }
}
</style>
