<template>
  <div class="jd-goods-item-wrap bgc-fff pr w-345"
       :class="{ 'jd-goods-item-wrap-small': size == 'small' }">
    <div class="jd-goods-item h-inherit">
      <div class="h-inherit">
        <img :src="goodsDetail?.goodsChannel == '1' ? goodsDetail?.goodsThumbImage : goodsDetail?.goodsThumbImageList[0]"
             class="w-inherit h-inherit" />
      </div>
      <div class="jd-goods-item-content pl10 pt10 pr5 pb10 pr"
           :class="{ 'jd-goods-item-content-small': size == 'small' }">
        <slot name="content">
          <div class="text-deal goods-title c-333">
            {{ type == 'order'?goodsDetail?.goodsName:data?.name }}
          </div>
          <div class="goods-price"
               :class="{ 'goods-price-small': size == 'small' }">
            <div v-if="type == 'order'"
                 class="flex justify-space-between">
              <div class="c-333 fs14 flex text-left">
                <span class="fs18 mr1">{{goodsDetail?.coinPrice}}</span>
                <span>工钱币</span>
              </div>
              <div class="c-999 fs12 flex text-left">
                <div class="pt2">
                  <van-icon name="cross"
                            class="c-797979 fs14 mr4" />
                </div>
                <span>{{goodsNum}}</span>
              </div>
            </div>
            <div v-else>
              <div class="c-FE6507 fs12">
                <span class="fs21 font-weight line-height-21 vertical-bottom">{{goodsDetail.coin_num}}</span>
                <span class="line-height-12">工钱币</span>
              </div>
              <div class="fs12 c-999 mt5 line-height-12">
                <del>¥{{goodsDetail.origin_money}}</del>
              </div>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent, reactive, toRefs, ref } from 'vue'
  export default defineComponent({
    props: {
      goodsDetail: {
        type: Object
      },
      size: {
        type: String,
        default: "normal",
      },
      type: {
        type: String,
        default: "normal",
      },
      goodsNum: {
        type: String
      }
    },
    setup(props) {
    }
  })
</script>
<style lang="scss" scoped>
  .jd-goods-item-wrap {
    height: 120px;
    border-radius: 6px;
    overflow: hidden;
    .jd-goods-item {
      display: flex;
    }
    .jd-goods-item-content {
      flex: 1;
      .goods-title {
        font-size: 16px;
        line-height: 24px;
      }
      .goods-price {
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 0;
      }
    }
  }
  .jd-goods-item-wrap-small {
    height: 110px;
    border-radius: 3px 0 0 3px;
    .jd-goods-item-content-small {
      padding: 6px 0 6px 10px;
      .goods-title {
        font-size: 14px;
        line-height: 21px;
      }
      .goods-price-small {
        bottom: 7px;
      }
    }
  }
</style>
